import styled from 'styled-components'

import {
  FacebookIntegrationSettings,
  AngiLeadsIntegrationSettings,
} from './components/IntegrationSettings'
import PageLayout from 'src/components/WafLayout/PageLayout'

const StyledSeparator = styled.div(({ theme }) => ({
  height: theme.space(8),
}))

const Integrations: React.FC = () => {
  return (
    <PageLayout
      title="Your Integrations"
      subtitle="Bring your tools together to make the most of your data. Sync contacts, content, engagement data, and more from your other apps and platforms."
      baseDataAttribute="settings-integrations"
    >
      <>
        <FacebookIntegrationSettings />
        <StyledSeparator />
      </>

      <AngiLeadsIntegrationSettings />
    </PageLayout>
  )
}

export default Integrations
