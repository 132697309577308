import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { IntegrationMappingType } from 'src/api/types'

export const useDeleteFacebookIntegrationMutation = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      const client = await getClient()

      await client.delete(`/locations/${locationId}/integrations/facebook`)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          'integration-status',
          {
            locationId,
            type: IntegrationMappingType.FACEBOOK,
          },
        ],
      })
    },
  })
}
