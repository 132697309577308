import logger from './utils/logger'

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void
  onUpdate?: (registration: ServiceWorkerRegistration) => void
}

export const register = (config?: Config) => {
  if ('serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)

    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

      // Using NODE_ENV since this values is set when doing the build of the app.
      // If the NODE_ENV is not production, we will check if the service worker is still valid.
      // Because in development, the service worker file may not be a valid JS file
      if (process.env.NODE_ENV !== 'production') {
        // Check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config)
      } else {
        // Is not local development. Just register service worker
        registerValidSW(swUrl, config)
      }
    })
  }
}

const registerValidSW = (swUrl: string, config?: Config) => {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing

        // Disabling eslint rule here since this implementation comes from CRA boilerplate
        // eslint-disable-next-line eqeqeq
        if (installingWorker == null) {
          return
        }

        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            logger.debug('Service worker successfully installed')
          }
        }
      }
    })
    .catch((error: unknown) => {
      logger.error('Error during service worker registration:', { error })
    })
}

const checkValidServiceWorker = (swUrl: string, config?: Config) => {
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type')

      if (
        response.status === 404 ||
        contentType?.indexOf('javascript') === -1
      ) {
        // No service worker found. Probably a different app. Reload the page.
        void navigator.serviceWorker.ready.then((registration) => {
          void registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config)
      }
    })
    .catch(() => {
      logger.debug(
        'No internet connection found. App is running in offline mode.'
      )
    })
}

export const unregister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        void registration.unregister()
      })
      .catch((error) => {
        const e = error as Error

        logger.error(e.message)
      })
  }
}
