import React from 'react'

import LeadInstantResponseSettings from './LeadInstantResponseSettings'
import { InstantResponseSettingsProps } from './types'
import { useInstantResponder } from 'src/containers/Settings/InstantResponders/hooks'
import { useFacebookIntegrationData } from 'src/containers/Settings/InstantResponders/hooks/facebookHooks'
import { useLocationContext } from 'src/contexts/LocationContext'
import { formatPhoneNumber } from 'src/utils'

type FacebookInstantResponseSettingsProps = InstantResponseSettingsProps

const FacebookInstantResponseSettings: React.FC<
  FacebookInstantResponseSettingsProps
> = ({ config: { isEnabled, responderId, responderName, template } }) => {
  const { activeLocation, locationId } = useLocationContext()

  const { onToggle, onUpdateMessage, isToggling, isUpdatingMessage } =
    useInstantResponder({
      isEnabled,
      locationId,
      source: responderName,
      responderId,
      defaultMessage: template,
    })
  const { isEnabled: shouldEnable, isConnected } = useFacebookIntegrationData({
    locationId,
  })

  const leadSourceName = 'Facebook instant response'
  let tooltipMessage = `In order to use the Facebook instant response feature, you'll need to connect your Facebook account.`
  const enableToggling = shouldEnable

  if (isConnected) {
    tooltipMessage = ''
  }

  return (
    <LeadInstantResponseSettings
      leadSourceName={leadSourceName}
      connectable={true}
      connected={isConnected}
      responderName={responderName}
      enabled={shouldEnable && isEnabled}
      message={template}
      onEnablementChange={onToggle}
      isEnabling={isToggling}
      onUpdateMessage={onUpdateMessage}
      isUpdatingMessage={isUpdatingMessage}
      messageLabel="Response to incoming Facebook message:"
      maxLength={160}
      isToggleDisabled={!enableToggling}
      sendVCard={false}
      hideVCardToggle
      tooltipMessage={tooltipMessage}
      snippets={[
        {
          label: 'Location Name',
          value: `{{{locationName}}}`,
          tooltip: activeLocation.name,
        },
        {
          label: 'Location Phone Number',
          value: `{{locationPhoneNumber}}`,
          tooltip: formatPhoneNumber(activeLocation.phoneNumber),
        },
      ]}
    />
  )
}

export default FacebookInstantResponseSettings
