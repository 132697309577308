import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { SidebarRoutes } from './Sidebar'
import { ReactComponent as CompanyLogo } from './assets/company-header-logo.svg'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'

export interface RouteItem {
  /**
   * Navigation link
   */
  to: string
  /**
   * Content displayed in the Navbar item
   */
  label: string
  /**
   * Render custom route item
   */
  render?: React.ReactElement
  /**
   * Can be set to place a middle space in the Navbar
   */
  isSpacer?: boolean
  /**
   * react-router-dom prevent exact
   */
  preventExactMatch?: boolean
  /**
   * Other pathnames that are part of this route. This is useful for highlighting active
   * items in the Navbar when navigating through Glaze pages that have different pathnames
   * within a section. E.g. Settings section is conformed by /settings and /email-customization
   */
  additionalRoutes?: string[]
  /**
   * Sidebar routes used for the Sidebar, also shared with the navbar for the Sidedrawer, defined
   * at useSidebarRoutes custom hook
   */
  sidebarRoutes?: SidebarRoutes[]
  /**
   * Icon (or React Element) to be placed left of the navigation item label
   */
  leftIcon?: React.ReactElement
  /**
   * Icon (or React Element) to be placed right of the navigation item label
   */
  rightIcon?: React.ReactElement
  disable?: boolean
  preventActive?: boolean
  addBorder?: boolean
}

export interface NavbarProps {
  /**
   * Array of Route Items to be rendered within the Navbar
   */
  routes: RouteItem[]

  dark?: boolean
}

interface HeaderProps {
  $dark?: boolean
}

export const StyledHeader = styled.header<HeaderProps>(({ $dark, theme }) => ({
  height: theme.heights.navbar,
  display: 'flex',
  padding: `${theme.space(1)} ${theme.space(3)}`,
  backgroundColor: $dark ? theme.colors.primary_1_dark : theme.colors.primary_1,
  justifyContent: 'space-between',
  alignItems: 'center',
  flexGrow: 1,
}))

const StyledSpacer = styled.div({
  flex: 1,
})

const StyledHeaderLogoContainer = styled.div(({ theme }) => ({
  width: theme.space(45),
  height: theme.space(14),
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
}))

const StyledNavbarLinkItem = styled.div(() => ({
  listStyleType: 'none',
  textDecoration: 'none',
  margin: `0`,
}))

interface StyledNavLinkProps {
  $isActive: boolean
  $smallDesktop: boolean
  $addBorder?: boolean
}
const StyledNavLink = styled(NavLink)<StyledNavLinkProps>(
  ({ $isActive, $smallDesktop, theme, $addBorder }) => ({
    color: theme.colors.base_10,
    backgroundColor: $isActive ? theme.colors.primary_2 : 'transparent',
    textDecoration: 'none',
    fontSize: '1.6rem',
    padding: `${theme.space($smallDesktop ? 3 : 2)}`,
    borderRadius: theme.constants.borderRadius,
    border: $addBorder ? `2px solid ${theme.colors.base_0}` : undefined,
    display: 'flex',
    alignItems: 'center',
    gap: theme.space(2),
  })
)
const Navbar: React.FC<NavbarProps> = ({ routes = [], dark }) => {
  const { pathname } = useLocation()
  const theme = useTheme()
  const navigate = useNavigate()
  const redirectMessagingHub = () => navigate('messaging-hub')

  const { isLargeScreen: smallDesktop } = useScreenSizes()

  return (
    <StyledHeader data-cy="navbar-container" $dark={dark}>
      <div onClick={redirectMessagingHub}>
        <StyledHeaderLogoContainer data-cy="navbar-logo-container">
          <CompanyLogo fill={theme.colors.base_0} />
        </StyledHeaderLogoContainer>
      </div>
      {routes.map((route, idx) => {
        if (route.isSpacer) {
          return <StyledSpacer key={idx} />
        }
        if (route.render) {
          return route.render
        }

        const { leftIcon, rightIcon } = route

        return (
          <StyledNavbarLinkItem
            key={idx}
            data-cy={`navbar-item-${route.label}`}
          >
            <StyledNavLink
              end={!route.preventExactMatch}
              to={route.to}
              $smallDesktop={smallDesktop}
              $addBorder={route.addBorder}
              $isActive={
                !route.preventActive &&
                (pathname.includes(
                  new URL(route.to, window.location.origin).pathname
                ) ||
                  !!route.additionalRoutes?.some((ar) =>
                    pathname.includes(
                      new URL(ar, window.location.origin).pathname
                    )
                  ))
              }
            >
              {leftIcon}
              {route.label}
              {rightIcon}
            </StyledNavLink>
          </StyledNavbarLinkItem>
        )
      })}
    </StyledHeader>
  )
}

export default Navbar
