import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import useAccountContext from 'src/contexts/AccountContext'
import Backdrop from 'src/stories/Backdrop'
import ExpandableContent from 'src/stories/ExpandableContent'
import { RouteItem } from 'src/stories/Navbar'
import Sidebar from 'src/stories/Sidebar'
import Sidedrawer from 'src/stories/Sidedrawer'
import { HamburgerIcon } from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { getLocationInformation } from 'src/utils'
import { UseLocationRouteParams } from 'src/utils/interfaces'

const SidedrawerContainer = styled.div(({ theme }) => ({
  padding: `0 ${theme.space(12)}`,
}))

const SidedrawerNavLink = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  color: theme.colors.base_100,
  textDecoration: 'none',
  fontSize: '2rem',
  gap: theme.space(2),
  padding: `${theme.space(5)} 0`,
}))

const StyledHamburgerIcon = styled(HamburgerIcon)(({ theme }) => ({
  margin: `${theme.space(7)} ${theme.space(4.5)} 0 ${theme.space(4.5)}`,
  cursor: 'pointer',
}))

interface MobileNavProps {
  routes: RouteItem[]
}

const MobileNav: React.FC<MobileNavProps> = ({ routes }) => {
  const { pathname } = useLocation()
  const { isExtraSmallScreen } = useScreenSizes()

  if (isExtraSmallScreen) {
    const campaignsIndex = routes.findIndex((r) => r.label === 'Campaigns')

    if (campaignsIndex !== -1) {
      routes.splice(campaignsIndex, 1)
    }
  }

  const [openDrawer, setOpenDrawer] = useState(false)

  const theme = useTheme()

  const { locationId } = useParams<UseLocationRouteParams>()

  const { legacyLocations: locations } = useAccountContext()

  const activeLocation = locations?.find((l) => l.locationId === +locationId!)

  const { isFreeTrialist } = getLocationInformation(activeLocation)

  useEffect(() => {
    setOpenDrawer(false)
  }, [pathname])

  return (
    <>
      <span>
        <StyledHamburgerIcon
          fill={theme.colors.primary_2}
          onClick={() => setOpenDrawer((o) => !o)}
        />
      </span>
      <Backdrop show={openDrawer} onClick={() => setOpenDrawer(false)} />
      <Sidedrawer onClose={() => setOpenDrawer(false)} open={openDrawer}>
        <SidedrawerContainer>
          {routes.map((r, idx) => {
            if (r.sidebarRoutes) {
              return (
                <ExpandableContent
                  data-cy={`navbar-item-${r.label}`}
                  key={`${r.label}${idx}`}
                  triggerText={r.label}
                  initiallyExpanded={
                    pathname ===
                      new URL(r.to, window.location.origin).pathname ||
                    !!r.additionalRoutes?.some((ar) => {
                      return (
                        pathname ===
                        new URL(ar, window.location.origin).pathname
                      )
                    })
                  }
                >
                  <Sidebar sidedrawer routes={r.sidebarRoutes} />
                </ExpandableContent>
              )
            } else {
              if (isFreeTrialist && r.render) {
                return r.render
              }

              return (
                <SidedrawerNavLink
                  key={`${r.label}${idx}`}
                  data-cy={`navbar-item-${r.label}`}
                  to={r.to}
                >
                  {r.leftIcon}
                  {r.label}
                </SidedrawerNavLink>
              )
            }
          })}
        </SidedrawerContainer>
      </Sidedrawer>
    </>
  )
}

export default MobileNav
