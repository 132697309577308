import { formatRelative } from 'date-fns'
import Linkify from 'linkify-react'
import React from 'react'
import styled from 'styled-components'

import { ConversationEventDirection } from 'src/api'
import {
  useGetMessageIcon,
  MessageIcon,
} from 'src/components/MessagingHub/hooks'
import { displayUserFriendlyMessage } from 'src/contexts/MhContext/utils'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Body } from 'src/stories/typography'

type SourceType = 'system' | ConversationEventDirection

const sourceTypeConfig = {
  system: {
    alignment: 'center',
  },
  [ConversationEventDirection.OUTGOING]: {
    alignment: 'flex-end',
  },
  [ConversationEventDirection.INCOMING]: {
    alignment: 'flex-start',
  },
}

interface SharedProps {
  sourceType: SourceType
  isAutomatedMessage: boolean
  isDesktop?: boolean
  errorMessage?: string
  isWarning?: boolean
  isEmptyBackground?: boolean
  isSimplified?: boolean
}

interface StyledErrorMessageProps {
  alignRight: boolean
}

const StyledMessageItemContainer = styled.div<SharedProps>(
  ({ theme, sourceType, isDesktop, isSimplified }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: sourceTypeConfig[sourceType].alignment,
    marginTop: theme.space(isSimplified ? 3 : 6),
    maxWidth: isDesktop ? '70%' : '95%',
  })
)

const StyledBubbleTitle = styled.p<SharedProps>(
  ({ theme, isAutomatedMessage }) => ({
    marginTop: 0,
    marginBottom: theme.space(1),
    color: isAutomatedMessage ? theme.colors.primary_1 : theme.colors.base_100,
    fontWeight: 500,
  })
)

const StyledMessageHeader = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.space(1),
  marginBottom: theme.space(2),
}))

const StyledBubble = styled.div<SharedProps>(
  ({
    theme,
    sourceType,
    isAutomatedMessage,
    errorMessage,
    isWarning,
    isEmptyBackground,
  }) => ({
    display: 'block',
    flexDirection: 'row',
    alignSelf: sourceTypeConfig[sourceType].alignment,
    padding: `${theme.space(3)} ${theme.space(4)}`,
    borderRadius: '8px',
    fontSize: '1.4rem',
    lineHeight: '20px',
    border: `${errorMessage ? '2px' : '1px'} solid ${
      errorMessage
        ? theme.colors.accent_2
        : isAutomatedMessage
        ? theme.colors.primary_2
        : sourceType === ConversationEventDirection.OUTGOING
        ? theme.colors.accent_3
        : isWarning
        ? theme.colors.accent_2
        : theme.colors.base_40
    }`,
    backgroundColor: isEmptyBackground
      ? theme.colors.base_0
      : isAutomatedMessage
      ? theme.colors.primary_1_10
      : sourceType === ConversationEventDirection.OUTGOING
      ? theme.colors.accent_3_15
      : isWarning
      ? theme.colors.accent_2_15
      : theme.colors.base_5,
    color: isAutomatedMessage
      ? theme.colors.base_40
      : sourceType === ConversationEventDirection.OUTGOING
      ? theme.colors.accent_3
      : isWarning
      ? theme.colors.accent_2
      : theme.colors.base_40,
  })
)

const StyledLinkify = styled(Linkify)(({ theme }) => ({
  a: {
    color: theme.colors.primary_2,
    lineBreak: 'anywhere',
  },
  margin: 0,
  whiteSpace: 'pre-wrap',
}))

const StyledErrorMessage = styled.p<StyledErrorMessageProps>(
  ({ theme, alignRight }) => ({
    color: theme.colors.accent_2,
    marginBottom: theme.space(2),
    marginTop: theme.space(1),
    fontSize: '1.2rem',
    fontWeight: '500',
    textAlign: alignRight ? 'right' : 'left',
  })
)

interface MessageBubbleProps extends React.PropsWithChildren {
  dataCy: string
  contactName: string
  date: string
  /**
   * It can be system, outgoing or incoming message
   */
  sourceType: SourceType
  errorMessage?: string
  isWarning?: boolean
  isSimplified?: boolean
  isEmptyBackground?: boolean
  isAutomatedMessage?: boolean
  icon?: MessageIcon
  showAsLoading?: boolean

  // at least one of `bodyDescription` or `body` must be provided
  bodyDescription?: string
  body?: string | JSX.Element
  bodyFooter?: string | JSX.Element
}

const MessageBubble: React.FC<MessageBubbleProps> = React.forwardRef<
  HTMLDivElement,
  MessageBubbleProps
>(
  (
    {
      body,
      dataCy,
      contactName,
      date,
      sourceType,
      bodyDescription,
      bodyFooter,
      errorMessage,
      isAutomatedMessage = false,
      isWarning = false,
      isSimplified = false,
      isEmptyBackground = false,
      icon,
      showAsLoading = false,
      children,
    },
    ref
  ) => {
    const { isLargeScreen: isLargeDesktop } = useScreenSizes()
    const failureMessage = displayUserFriendlyMessage(errorMessage)

    const titleIcon = useGetMessageIcon(icon)

    return (
      <StyledMessageItemContainer
        data-cy={dataCy}
        isDesktop={isLargeDesktop}
        sourceType={sourceType}
        isAutomatedMessage={isAutomatedMessage}
        isSimplified={isSimplified}
        errorMessage={errorMessage}
        ref={ref}
      >
        {!isSimplified && (
          <StyledMessageHeader>
            {titleIcon}
            <Body
              as="h2"
              data-cy={dataCy + '-name'}
              ellipsis
              customColor={isAutomatedMessage ? 'primary_1' : undefined}
              fontWeight="bold"
              color="darker"
            >
              {contactName}
            </Body>
            <Body
              data-cy={dataCy + '-timestamp'}
              size="small"
              color="lighter"
              ellipsis
            >
              {formatRelative(new Date(date), new Date())}
            </Body>
          </StyledMessageHeader>
        )}
        <StyledBubble
          data-cy={dataCy + '-message'}
          isWarning={isWarning}
          sourceType={sourceType}
          isAutomatedMessage={isAutomatedMessage}
          errorMessage={errorMessage}
          isEmptyBackground={isEmptyBackground}
        >
          {showAsLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {bodyDescription && (
                <StyledBubbleTitle
                  sourceType={sourceType}
                  isAutomatedMessage={isAutomatedMessage}
                >
                  {bodyDescription}
                </StyledBubbleTitle>
              )}
              {React.Children.toArray(children).some((child) =>
                React.isValidElement(child)
              ) ? (
                <>{children}</>
              ) : (
                <StyledLinkify tagName="p" options={{ target: '_blank' }}>
                  {body}
                </StyledLinkify>
              )}
              {bodyFooter && (
                <Body
                  data-cy={dataCy + '-footer'}
                  size="small"
                  color="lighter"
                  ellipsis
                >
                  {bodyFooter}
                </Body>
              )}
            </>
          )}
        </StyledBubble>

        {errorMessage && (
          <StyledErrorMessage
            alignRight={sourceType === ConversationEventDirection.OUTGOING}
          >
            {failureMessage}
          </StyledErrorMessage>
        )}
      </StyledMessageItemContainer>
    )
  }
)

export default MessageBubble
