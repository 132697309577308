import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { IntegrationMappingType } from 'src/api/types'

export interface ExtendFacebookTokenParams {
  accessToken: string
}

export const useExtendFacebookToken = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: ExtendFacebookTokenParams) => {
      return getClient().then((client) =>
        client
          .post<{ accessToken: string }>(
            `/locations/${locationId}/integrations/facebook/access-token`,
            {
              accessToken: params.accessToken,
            }
          )
          .then((r) => r.data)
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          'integration-status',
          {
            locationId,
            type: IntegrationMappingType.FACEBOOK,
          },
        ],
      })
    },
  })
}
