import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { ListResourceParams } from 'src/api/types'

export type GetContactsCsvParams = Omit<ListResourceParams, 'pagination'>

export const useGetContactsCsv = ({
  locationId,
  search,
  sort,
}: GetContactsCsvParams) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'contacts-csv',
      {
        locationId,
        search,
        sort,
      },
    ] as const,
    queryFn: async ({ queryKey: [, params] }) => {
      const searchParams: Record<string, unknown> = {
        export: true,
      }

      if (params.sort?.direction && params.sort.field) {
        searchParams.direction = params.sort?.direction
        searchParams.field = params.sort.field
      }
      if (params.search) {
        searchParams.search = params.search
      }

      return getClient()
        .then((client) =>
          client.get<string>(`/locations/${params.locationId}/contacts`, {
            params: searchParams,
          })
        )
        .then((r) => r.data)
    },
    enabled: false,
  })
}
