import { useEffect, useState } from 'react'

import {
  useCreateWebSubscription,
  useDeleteWebSubscription,
  useGetWebSubscriptions,
} from 'src/api'
import logger from 'src/utils/logger'

const serviceWorkerServerPublicKey = process.env.REACT_APP_SW_SERVER_PUBLIC_KEY

const areNotificationsDisabled = (permission?: NotificationPermission) =>
  !serviceWorkerServerPublicKey || !permission || permission === 'denied'

const getNotificationPermission = () => {
  if ('Notification' in window) {
    return Notification.permission
  }

  logger.error('Notifications are not supported in this browser')
}

export const useNotifications = () => {
  const { data: webSubscriptions } = useGetWebSubscriptions()
  const { mutateAsync: createWebSubscription, isPending: isCreating } =
    useCreateWebSubscription()
  const { mutateAsync: deleteWebSubscription, isPending: isDeleting } =
    useDeleteWebSubscription()
  const [areNotificationsOn, setAreNotificationOn] = useState(false)
  const [isDisabled, setIsDisabled] = useState(
    areNotificationsDisabled(getNotificationPermission())
  )

  const createNotificationSubscription = async () => {
    const serviceWorker = await navigator.serviceWorker.ready

    return serviceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: serviceWorkerServerPublicKey,
    })
  }

  const getCurrentSubscription = async () => {
    const serviceWorker = await navigator.serviceWorker.ready

    return serviceWorker.pushManager.getSubscription()
  }

  useEffect(() => {
    const initializeFn = async () => {
      const existingSubscription = await getCurrentSubscription()
      const hasBrowserSubscription = !!existingSubscription
      const existingStoredSubscription = webSubscriptions?.find(
        (s) => s.subscriptionUrl === existingSubscription?.endpoint
      )

      if (
        hasBrowserSubscription &&
        !existingStoredSubscription &&
        Array.isArray(webSubscriptions)
      ) {
        await createWebSubscription({
          subscription: existingSubscription,
        })
      }

      const isSubscribed =
        hasBrowserSubscription && !!existingStoredSubscription

      setAreNotificationOn(isSubscribed)
    }

    void initializeFn()

    setIsDisabled(areNotificationsDisabled(getNotificationPermission()))
  }, [createWebSubscription, deleteWebSubscription, webSubscriptions])

  const askUserPermission = async () => {
    try {
      const notificationPermission = await Notification.requestPermission()

      return notificationPermission
    } catch {
      return 'default'
    }
  }

  const onToggle = async () => {
    if (!isDisabled) {
      if (!areNotificationsOn) {
        const permission = await askUserPermission()

        if (permission === 'granted') {
          const subscription = await createNotificationSubscription()

          const hasSubscription = !!webSubscriptions?.some(
            (webSubscription) =>
              webSubscription.subscriptionUrl === subscription.endpoint
          )

          if (!hasSubscription) {
            await createWebSubscription({
              subscription,
            })
          }

          setAreNotificationOn(true)
        } else {
          setAreNotificationOn(false)
          setIsDisabled(areNotificationsDisabled(permission))
        }

        return
      }

      const existingSubscription = await getCurrentSubscription()

      if (existingSubscription) {
        const succeeded = await existingSubscription.unsubscribe()

        if (succeeded) {
          const webSubscription = webSubscriptions?.find(
            (s) => s.subscriptionUrl === existingSubscription.endpoint
          )

          if (webSubscription) {
            await deleteWebSubscription({
              subscriptionId: webSubscription.id,
            })
          }
        }
      }

      setAreNotificationOn(false)
      setIsDisabled(false)
    }
  }

  return {
    areNotificationsOn,
    isDisabled: isDisabled || isCreating || isDeleting,
    onToggle,
  }
}
