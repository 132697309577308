import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { Paginated, WebSubscription } from 'src/api/types'

export const useGetWebSubscriptions = () => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: ['web-subscriptions'] as const,
    queryFn: async () => {
      return getClient()
        .then((client) =>
          client.get<Paginated<WebSubscription>>('/users/subscriptions')
        )
        .then((r) => r.data.data)
    },
  })
}
