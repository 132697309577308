import {
  IntegrationMappingStatus,
  IntegrationMappingType,
  useGetIntegrationStatus,
  useGetLocationFeatureFlag,
} from 'src/api'
import Constants from 'src/lib/Constants'

interface UseFacebookIntegrationDataResponse {
  isEnabled: boolean
  isConnected: boolean
}

interface UseFacebookIntegrationDataParams {
  locationId: number
}

export const useFacebookIntegrationData = ({
  locationId,
}: UseFacebookIntegrationDataParams): UseFacebookIntegrationDataResponse => {
  const { data: integrationStatus } = useGetLocationFeatureFlag({
    locationId,
    featureFlagName: Constants.FeatureFlags.isFacebookTextingEnabled,
    defaultValue: 0,
  })
  const { data: facebookIntegration } = useGetIntegrationStatus({
    locationId,
    type: IntegrationMappingType.FACEBOOK,
  })

  return {
    isEnabled: integrationStatus.value === 1,
    isConnected: facebookIntegration?.status === IntegrationMappingStatus.READY,
  }
}
