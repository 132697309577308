import { useMemo } from 'react'
import { useTheme } from 'styled-components'

import { ConversationEventType } from 'src/api'
import {
  AngiLeadsIcon,
  PhoneIcon,
  CompanyIcon,
  FacebookMessengerIcon,
  SmsIcon,
  ReceptionistIcon,
  PhonelyIcon,
  SpamIcon,
} from 'src/stories/assets'

export type MessageIcon = ConversationEventType | 'Signpost' | 'Spam'

export const useGetMessageIcon = (icon?: MessageIcon): JSX.Element | null => {
  const theme = useTheme()

  const titleIcon = useMemo<JSX.Element | null>(() => {
    const iconStyles: React.CSSProperties = {}

    const icons: Record<MessageIcon, JSX.Element> = {
      ANGI_LEAD_EVENT: <AngiLeadsIcon style={iconStyles} />,
      FACEBOOK_EVENT: <FacebookMessengerIcon style={iconStyles} />,
      LR_SUMMARY_EVENT: (
        <ReceptionistIcon style={iconStyles} fill={theme.colors.base_100} />
      ),
      LR_VOICEMAIL_EVENT: (
        <ReceptionistIcon style={iconStyles} fill={theme.colors.base_100} />
      ),
      MH_WELCOME_MESSAGE_EVENT: <CompanyIcon style={iconStyles} />,
      PHONE_CALL_EVENT: (
        <PhoneIcon style={iconStyles} fill={theme.colors.base_100} />
      ),
      PHONELY_EVENT: <PhonelyIcon style={iconStyles} />,
      Signpost: <CompanyIcon style={iconStyles} />,
      SMS_EVENT: <SmsIcon style={iconStyles} fill={theme.colors.base_100} />,
      Spam: <SpamIcon fill={theme.colors.accent_2} style={iconStyles} />,
    }

    return (icon && icons[icon]) || null
  }, [icon, theme])

  return titleIcon
}
