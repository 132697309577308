import React from 'react'
import styled from 'styled-components'

import Toggle from 'src/stories/Toggle'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Body } from 'src/stories/typography'

export const ToggleActionContainer = styled.span(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.space(4),
  transition: 'color 0.3s ease',
}))

export interface ToggleActionProps {
  dataCy: string
  label: string
  disabled: boolean
  checked: boolean
  onClick: () => void
  onEnablementChange?: (value: boolean) => void
}

const ToggleAction: React.FC<ToggleActionProps> = ({
  dataCy,
  disabled,
  label,
  checked,
  onClick,
  onEnablementChange,
}) => {
  const { isSmallScreen: isDesktop } = useScreenSizes()

  return (
    <ToggleActionContainer>
      {isDesktop && <Body color="light">{label}</Body>}
      <Toggle
        dataCy={dataCy}
        disabled={disabled}
        checked={checked}
        onChange={(e) => {
          onClick()
          onEnablementChange?.(e.target.checked)
        }}
      />
    </ToggleActionContainer>
  )
}

export default ToggleAction
