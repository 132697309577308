import React from 'react'
import styled from 'styled-components'

import QuestionTooltip from 'src/components/QuestionTooltip'
import ToggleAction from 'src/components/ToggleAction'
import PageLayout from 'src/components/WafLayout/PageLayout'
import { useNotifications } from 'src/hooks/useNotifications'
import PageSectionContainer from 'src/stories/PageSectionContainer'
import { Heading } from 'src/stories/typography'

const TitleContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.space(2),
}))

const Notifications: React.FC = () => {
  const baseDataAttribute = 'settings-notifications'
  const { areNotificationsOn, isDisabled, onToggle } = useNotifications()

  return (
    <PageLayout
      title="Notification Settings"
      subtitle=""
      baseDataAttribute={baseDataAttribute}
    >
      <PageSectionContainer
        title={
          <TitleContainer>
            <Heading as="h3" size="medium">
              Push Notifications
            </Heading>
            {isDisabled && (
              <QuestionTooltip tooltipMessage="Please reset your browser permissions to enable notifications" />
            )}
          </TitleContainer>
        }
        subtitle="Receive instant notifications about new messages, updates, and more."
        variant="inverted"
        isRounded
        action={ToggleAction}
        actionProps={{
          label: 'Send Push Notifications',
          dataCy: '',
          disabled: isDisabled,
          checked: areNotificationsOn,
          onClick: () => null,
          onEnablementChange: onToggle,
        }}
        preventHiding
      ></PageSectionContainer>
    </PageLayout>
  )
}

export default Notifications
