import React, {
  MouseEventHandler,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import styled, { CSSObject } from 'styled-components'

import { ChevronIcon } from './Icons/ChevronIcon'
import { Body, Heading, HeadingProps } from './typography'
import { Button, ButtonProps } from 'src/stories/Button'

export type PageSectionContainerVariants = 'default' | 'inverted'

const Container = styled.section<{
  variant: PageSectionContainerVariants
  disabled?: boolean
}>(({ theme, disabled }) => ({
  filter: disabled ? 'opacity(70%)' : 'none',
}))

const SharedContainer = styled.div<{
  isExpanded?: boolean
  isRounded?: boolean
  variant: PageSectionContainerVariants
  isWrapped?: boolean
  isDesktop?: boolean
}>(
  ({
    theme,
    isRounded: rounded,
    isExpanded,
    variant,
    isWrapped,
    isDesktop,
  }) => {
    const borderRadius = rounded
      ? theme.constants.largeBorderRadius
      : theme.constants.borderRadius

    return {
      background:
        variant === 'default' && isWrapped
          ? theme.colors.base_3
          : theme.colors.base_0,
      border: isWrapped ? `1px solid ${theme.colors.base_20}` : 'unset',
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
      borderBottomLeftRadius: isExpanded ? 0 : borderRadius,
      borderBottomRightRadius: isExpanded ? 0 : borderRadius,
      paddingLeft: theme.space(isWrapped ? (isDesktop ? 8 : 5) : 0),
      paddingRight: theme.space(isWrapped ? (isDesktop ? 8 : 5) : 0),
    }
  }
)

export const HeadingContainer = styled(SharedContainer)(({ theme }) => ({
  paddingTop: theme.space(3),
  paddingBottom: theme.space(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(2),
}))

export const TitleContainer = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.space(3),
}))

export const ContentContainer = styled(SharedContainer)<{
  contentHeight: number
  shouldShow: boolean
}>(({ theme, contentHeight: bodyHeight, shouldShow }) => ({
  borderTop: '0',
  borderTopLeftRadius: '0',
  borderTopRightRadius: '0',
  transition: 'all 0.3s ease-out',
  transitionProperty: 'max-height, border-radius',
  maxHeight: shouldShow ? `calc(${bodyHeight}px + ${theme.space(8)})` : 0,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}))

export const ContentBody = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.space(6),
  paddingBottom: theme.space(6),
}))

const StyledChevronIcon = styled(ChevronIcon)(({ theme }) => ({
  fill: theme.colors.base_50,
  cursor: 'pointer',
}))

export const PageSectionContainerAction: React.FC<ButtonProps> = ({
  baseDataAttribute = 'section-container',
  style,
  ...props
}) => {
  return (
    <Button
      displayAsText
      size="large"
      style={{
        ...style,
      }}
      baseDataAttribute={baseDataAttribute + '-action'}
      {...props}
    />
  )
}

interface BaseActionProps {
  onClick?: MouseEventHandler
}

export type PageSectionContainerPropsWithAction<
  ActionPropsType extends BaseActionProps = BaseActionProps
> = {
  /**
   * The action component to be rendered
   */
  action: React.ComponentType<ActionPropsType>
  /**
   * The props to be passed to the action component
   */
  actionProps: ActionPropsType
  /**
   * If the section should prevent hiding the content when the action is clicked
   */
  preventHiding?: boolean
}

type PropsWithoutAction = {
  action?: false | undefined
  actionProps?: never
  preventHiding?: never
}

type StringTitleProps = {
  /**
   * The principal section title
   */
  title: string
  titleProps?: HeadingProps
}

type TitleProps = {
  title?: JSX.Element
  titleProps?: never
}

interface BasePageSectionContainerProps extends React.PropsWithChildren {
  /**
   * The section subtitle
   */
  subtitle?: string | JSX.Element
  /**
   * The variant section style
   */
  variant?: PageSectionContainerVariants
  /**
   * Visual filter to show as disabled
   * but still need validation to internal components to prevent user of editing the component
   */
  disabled?: boolean
  /**
   * childrenStyle is CSSObject value to add style to the children
   */
  childrenStyle?: CSSObject
  /**
   * Style object to add to the container
   */
  style?: CSSObject
  /**
   * Base Data attribute.
   * Will append `-container`, `-header`, `-title`, and `-subtitle` to containing elements
   */
  baseDataAttribute?: string
  /**
   * If the section should have rounded corners
   */
  isRounded?: boolean
  /**
   * If the section should be wrapped in a border
   */
  isWrapped?: boolean
  /**
   * If the children should be shown or hidden; override default behavior
   */
  shouldShow?: boolean
}

export type PageSectionContainerProps<ActionPropsType extends BaseActionProps> =
  BasePageSectionContainerProps &
    (
      | PageSectionContainerPropsWithAction<ActionPropsType>
      | PropsWithoutAction
    ) &
    (StringTitleProps | TitleProps)

/**
 * Section container to separate different contents
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const PageSectionContainer = <ActionProps extends BaseActionProps = {}>({
  subtitle,
  variant = 'default',
  disabled,
  childrenStyle,
  style,
  baseDataAttribute = 'page-section',
  isRounded = false,
  children,
  isWrapped = true,
  shouldShow,
  ...rest
}: PageSectionContainerProps<ActionProps>) => {
  const [showAll, setShowAll] = useState(true)
  const [contentHeight, setContentHeight] = useState(0)
  const contentRef = useRef<HTMLDivElement>(null)

  const isControlledComponent = typeof shouldShow === 'boolean'
  const shouldShowAll = isControlledComponent ? shouldShow : showAll

  const onClickAction = () => {
    if (disabled) return
    if (isControlledComponent) return

    setShowAll((v) => !v)
  }

  useLayoutEffect(() => {
    const container = contentRef.current

    if (!container) return

    const checkOverflow = () => {
      const hasOverflow = container.scrollHeight > contentHeight

      if (hasOverflow) {
        setContentHeight(
          shouldShowAll ? contentRef.current?.clientHeight ?? 500 : 0
        )
      }
    }

    const observer = new ResizeObserver(checkOverflow)

    observer.observe(container)

    // Initial check for overflow
    checkOverflow()

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect()
    }
  }, [contentRef.current?.clientHeight, contentHeight, shouldShowAll])

  const Action =
    rest.action === false
      ? null
      : (rest.action && (
          <rest.action
            {...rest.actionProps}
            onClick={(event) => {
              if (disabled) return
              if (!rest.preventHiding) {
                onClickAction()
              }
              rest.actionProps.onClick?.(event)
            }}
          />
        )) || (
          <StyledChevronIcon
            direction={shouldShowAll ? 'down' : 'up'}
            onClick={onClickAction}
          />
        )
  const Subtitle =
    typeof subtitle === 'string' ? (
      <Body data-cy={`${baseDataAttribute}-subtitle`} as="p" size="medium">
        {subtitle}
      </Body>
    ) : (
      <>{subtitle}</>
    )
  const Title =
    typeof rest.title === 'string' ? (
      <Heading
        data-cy={`${baseDataAttribute}-title`}
        as="h2"
        size="large"
        {...(rest.titleProps || {})}
      >
        {rest.title}
      </Heading>
    ) : (
      <>{rest.title}</>
    )

  return (
    <Container
      id={baseDataAttribute}
      data-cy={baseDataAttribute + '-container'}
      variant="default"
      disabled={disabled}
      style={style}
    >
      {/* Heading */}
      <HeadingContainer
        data-cy={`${baseDataAttribute}-heading-container`}
        isRounded={isRounded}
        isExpanded={!!children && shouldShowAll}
        variant={variant}
        isWrapped={isWrapped}
      >
        <TitleContainer data-cy={`${baseDataAttribute}-title-container`}>
          {Title}
          {Action}
        </TitleContainer>
        {Subtitle}
      </HeadingContainer>

      {/* Content */}
      {shouldShowAll && children && (
        <ContentContainer
          data-cy={`${baseDataAttribute}-content-container`}
          isRounded={isRounded}
          variant={variant}
          isWrapped={isWrapped}
          contentHeight={contentHeight}
          shouldShow={shouldShowAll}
        >
          <ContentBody
            ref={contentRef}
            data-cy={`${baseDataAttribute}-content-body`}
            style={childrenStyle}
          >
            {children}
          </ContentBody>
        </ContentContainer>
      )}
    </Container>
  )
}

export default PageSectionContainer
