import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getOutgoingEventType } from './utils'
import {
  Conversation,
  ConversationEvent,
  SmsRegistrationStatus,
  WebSocketPageEvent,
  isConversationEvent,
  useCreateConversationEvent,
  useGetContact,
} from 'src/api'
import { useCreateConversationEventAttachments } from 'src/api/hooks/mutations/useCreateConversationEventAttachmentsMutation'
import { getContactDisplayName } from 'src/client/formatters'
import { Segment } from 'src/client/interfaces/Segments'
// import { SmsRegistrationStatus } from 'src/client/interfaces/SmsRegistration'
import { OpenConversationContactDetails } from 'src/containers/MessagingHub/types'
import { useConversationMessagesContext } from 'src/contexts/ConversationMessagesContext'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import { DEFAULT_ZERO_STATE_CONVERSATION_ID } from 'src/contexts/ConversationsListContext/utils'
import { useLocationContext } from 'src/contexts/LocationContext'
import {
  ConversationInfoEvent,
  HandleSendMessage,
} from 'src/contexts/MhContext/types'
// import {
//   handleInflightErrors,
//   handleUploadMedia,
//   reportProcessDone,
// } from 'src/contexts/MhContext/utils'
import Constants from 'src/lib/Constants'
import { Button } from 'src/stories/Button'
import { generateLocationUrl } from 'src/utils'
// import { Button } from 'src/stories/Button'
// import { generateLocationUrl } from 'src/utils'
import logger from 'src/utils/logger'

export interface UseMessagingHubData {
  // Conversations Pane
  conversationsListIsLoading: boolean
  conversations: Conversation[]
  openConversationId?: number
  conversationListFilter: string
  segments: Segment[]
  isOpenConversationChangeInFlight: boolean
  isZeroState: boolean
  hasMoreConversations: boolean
  getMoreConversations: () => Promise<void>
  setOpenConversationId: (conversationId?: number) => void
  setConversationListFilter: (filter: string) => void
  mutateConversationArchived: ReturnType<
    typeof useConversationsListContext
  >['mutateConversationArchived']
  mutateConversationRead: ReturnType<
    typeof useConversationsListContext
  >['mutateConversationRead']

  // Messages Pane
  loadingConversationContactDetails: boolean
  openConversationContactDetails: OpenConversationContactDetails
  loadingConversationMessages: boolean
  messages: ConversationEvent[]
  infoEvents: ConversationInfoEvent[]
  isReplyable: boolean
  docTypeToReply: string
  handleSendMessage: HandleSendMessage
  refetchOpenConversationContactDetails: () => Promise<void>
  setIsOpenConversationChangeInFlight: React.Dispatch<
    React.SetStateAction<boolean>
  >
}

export interface UseAttachmentsUpload {
  storeAttachmentsAndGetS3Paths: (
    locationId: number,
    conversationId: number,
    medias: File[]
  ) => Promise<string[]>
}

/**
 * This hook is a helper to manage the data used by the MhContext (conversations, messages, contacts)
 *
 * **Do not use it directly**
 */
export const useMessagingHubData = (): UseMessagingHubData => {
  const navigate = useNavigate()

  // Conversations Pane
  const {
    conversations,
    preventUiUpdates,
    getConversation,
    setPreventUiUpdates,
    socket,
    openConversationId,
    refetch: refetchConversationsList,
    ...conversationListContext
  } = useConversationsListContext()

  const [
    isOpenConversationChangeInFlight,
    setIsOpenConversationChangeInFlight,
  ] = useState(false)

  // Contact
  const [
    loadingConversationContactDetails,
    setLoadingConversationContactDetails,
  ] = useState(true)
  const [openConversationContactDetails, setOpenConversationContactDetails] =
    useState<OpenConversationContactDetails>({
      name: '',
      firstName: '',
      lastName: '',
      tagIds: [],
    })

  // Messages
  const {
    messages,
    isLoading: loadingConversationMessages,
    refetch: refetchMessagesList,
  } = useConversationMessagesContext()
  const { locationId, activeLocation } = useLocationContext()
  const { mutateAsync: sendMessage } = useCreateConversationEvent()
  const { storeAttachmentsAndGetS3Paths } = useAttachmentsUpload()

  const conversation = getConversation(openConversationId)

  const { data: contact, refetch: refetchContact } = useGetContact({
    locationId,
    contactId:
      conversation?.contactId && conversation?.contactId > 0
        ? conversation.contactId
        : undefined,
  })

  const [isReplyable, setIsReplyable] = useState(true)
  const [infoEvents, setInfoEvents] = useState<ConversationInfoEvent[]>([])

  const isZeroState = conversations.length <= 0

  const _getOpenConversationContactDetails = useCallback(
    (isZeroStateEnabled: boolean, conversationId?: number) => {
      if (
        isZeroStateEnabled ||
        conversationId === DEFAULT_ZERO_STATE_CONVERSATION_ID
      ) {
        setOpenConversationContactDetails({
          name: 'Signpost',
          firstName: 'Signpost',
          lastName: '',
          email: Constants.Branding.companySupportEmail,
          phoneNumber: '(844) 202-2015',
          tagIds: [],
        })

        setLoadingConversationContactDetails(false)

        return
      }
      setLoadingConversationContactDetails(true)

      let _openConversationContactDetails: OpenConversationContactDetails = {
        name: '',
        firstName: '',
        lastName: '',
        tagIds: [],
      }

      if (contact) {
        const {
          id,
          firstName,
          lastName,
          phoneNumber,
          emailAddress,
          channels,
          tagIds,
          // channels,
          // details: { emailOptIn, lifecycleStage, notes, smsOptIn, source },
          // primaryEmailAddress,
          // primaryPhoneNumber,
          // primarySegmentId,
          // subscribed,
          // segments: contactSegments,
          // updatedAt,
        } = contact

        const name = getContactDisplayName(firstName, lastName, phoneNumber)

        _openConversationContactDetails = {
          id,
          name,
          firstName,
          lastName,
          hasWrittenName: !!firstName || !!lastName,
          email: emailAddress,
          phoneNumber,
          tagIds,
          // emailOptIn,
          // smsOptIn,
          // merchantSubscribed: subscribed,
          isSmsReachable: channels.some((c) => !!c.phoneNumber),
          // lifecycleStage,
          // source,
          // segmentIds: contactSegments.map((segment) => segment.id),
          // primarySegmentId,
          // notes,
          // updatedAt,
          // channels,
        }
      }

      setOpenConversationContactDetails(_openConversationContactDetails)
      setLoadingConversationContactDetails(false)
    },
    [contact]
  )

  useEffect(() => {
    const initialize = () => {
      _getOpenConversationContactDetails(isZeroState, openConversationId)
      setIsOpenConversationChangeInFlight(false)
    }

    initialize()
  }, [_getOpenConversationContactDetails, isZeroState, openConversationId])

  const refetchOpenConversationContactDetails = useCallback(async () => {
    await refetchContact()
    _getOpenConversationContactDetails(isZeroState, openConversationId)
  }, [
    refetchContact,
    openConversationId,
    isZeroState,
    _getOpenConversationContactDetails,
  ])

  // TODO: Implement when sending is enabled
  const docTypeToReply = useMemo(
    () => {
      // const messageToReply = getLastMessageReceivedFromMessagesList(messagesList)

      // return messageToReply ? getLegacyDocumentType(messageToReply) : ''
      return ''
    },
    [
      /*messages*/
    ]
  )

  /**
   * TODO: Re-implement when sending is re-enabled
    Display a temporary placeholder "sent message", then actually send,
    and update the conversation list with fresh API data.
    This includes selecting what method & channel to use for the send,
    including in cases where the contact has multiple phone channels.
   */
  const handleSendMessage = useCallback<HandleSendMessage>(
    async (message, medias, sendVCard, isCustomerNotMarketingSubscribed) => {
      if (!messages.length || !openConversationId || openConversationId <= 0) {
        return
      }

      const mostRecentEvent = messages[0]
      const type = getOutgoingEventType(mostRecentEvent)

      if (!type) {
        return
      }

      try {
        setPreventUiUpdates(true)

        let mediaUrls: string[] = await storeAttachmentsAndGetS3Paths(
          locationId,
          openConversationId,
          medias
        )

        await sendMessage({
          channelId: mostRecentEvent.channelId,
          message,
          type,
          conversationId: openConversationId,
          locationId,
          sendVCard,
          mediaUrls,
        })
        await Promise.allSettled([
          refetchMessagesList(),
          refetchConversationsList(),
        ])
      } catch (error) {
        logger.error('error', { error })
        toast.error('Message not sent, Something went wrong. Please try again.')
      } finally {
        setPreventUiUpdates(false)
      }
    },
    [
      messages,
      openConversationId,
      locationId,
      setPreventUiUpdates,
      sendMessage,
      storeAttachmentsAndGetS3Paths,
      refetchMessagesList,
      refetchConversationsList,
    ]
  )

  useEffect(() => {
    if (openConversationId && openConversationId > 0 && socket) {
      const listener = (event: WebSocketPageEvent) => {
        const shouldRefetchMessages = event.some(
          ({ conversationId }) => conversationId === openConversationId
        )

        logger.debug('WAF WS MhContext - poll - Handling page event', {
          event,
          shouldRefetchMessages,
        })

        if (shouldRefetchMessages) {
          void refetchMessagesList()
        }
      }

      socket.on('page', listener)

      return () => {
        socket.off('page', listener)
      }
    }
  }, [socket, openConversationId, navigate, refetchMessagesList])

  useEffect(() => {
    if (!preventUiUpdates) {
      const showA2pRegisterCallToAction = !activeLocation.registrationStatus
      const showA2pCheckRegistrationCallToAction =
        activeLocation?.registrationStatus &&
        activeLocation?.registrationStatus !== SmsRegistrationStatus.APPROVED

      const mostRecentMessage = messages[0]

      const replyableConversation =
        !!mostRecentMessage && !!getOutgoingEventType(mostRecentMessage)

      const newInfoEvents: ConversationInfoEvent[] = []

      if (
        mostRecentMessage &&
        isConversationEvent(mostRecentMessage, 'FACEBOOK_EVENT') &&
        !replyableConversation
      ) {
        newInfoEvents.unshift({
          type: 'warning',
          message:
            'Facebook does not allow responses to conversations older than 24 hours. Please follow up with an email or text message directly.',
        })
      }

      if (
        activeLocation.primaryRentedPhoneNumberId &&
        showA2pRegisterCallToAction
      ) {
        newInfoEvents.unshift({
          type: 'warning',
          message: (
            <span>
              Phone carriers require registration to prevent spam messages.
              Click{' '}
              <Button
                label="here"
                href={generateLocationUrl(
                  locationId,
                  '/settings/business/sms-registration'
                )}
                asLink
                displayAsText
              />{' '}
              to register your business and ensure successful message delivery.
            </span>
          ),
        })
      }

      if (
        activeLocation.primaryRentedPhoneNumberId &&
        showA2pCheckRegistrationCallToAction
      ) {
        newInfoEvents.unshift({
          type: 'warning',
          message: (
            <span>
              Phone carriers require registration to prevent spam messages.
              Click{' '}
              <Button
                label="here"
                href={generateLocationUrl(locationId, '/settings/business')}
                asLink
                displayAsText
              />{' '}
              to check your pending registration status to ensure successful
              message delivery.
            </span>
          ),
        })
      }

      setIsReplyable(replyableConversation)
      setInfoEvents(newInfoEvents)
    }
  }, [
    messages,
    preventUiUpdates,
    openConversationContactDetails,
    locationId,
    activeLocation.primaryRentedPhoneNumberId,
    activeLocation.registrationStatus,
  ])

  return {
    segments: [],
    // Conversations Pane
    conversationsListIsLoading: conversationListContext.isLoading,
    hasMoreConversations: conversationListContext.hasMoreConversations,
    conversations,
    openConversationId,
    isZeroState,
    conversationListFilter: conversationListContext.conversationListFilter,
    getMoreConversations: conversationListContext.getMoreConversations,
    setOpenConversationId: conversationListContext.setOpenConversationId,
    setConversationListFilter:
      conversationListContext.setConversationListFilter,
    mutateConversationArchived:
      conversationListContext.mutateConversationArchived,
    mutateConversationRead: conversationListContext.mutateConversationRead,

    // Messages Pane
    loadingConversationContactDetails,
    openConversationContactDetails,
    isOpenConversationChangeInFlight,
    loadingConversationMessages,
    messages,
    infoEvents,
    isReplyable,
    docTypeToReply,
    handleSendMessage,
    refetchOpenConversationContactDetails,
    setIsOpenConversationChangeInFlight,
  }
}

export const useAttachmentsUpload = (): UseAttachmentsUpload => {
  const { mutateAsync: sendAttachments } =
    useCreateConversationEventAttachments()

  const storeAttachmentsAndGetS3Paths = useCallback(
    async (locationId: number, conversationId: number, medias: File[]) => {
      if (!medias.length) {
        return []
      }

      let mediaUrls: string[] = []

      const attachmentsResponse = await sendAttachments({
        locationId,
        conversationId,
        attachments: medias.map((media) => ({
          filename: media.name,
          mimeType: media.type,
        })),
      })

      mediaUrls = attachmentsResponse.attachments.map(
        (attachment) => attachment.path
      )

      for (const attachment of attachmentsResponse.attachments) {
        const media = medias.find((m) => attachment.path.endsWith(m.name))

        if (!media) {
          continue
        }

        try {
          await axios.put(attachment.signedUrl, media, {
            headers: {
              'Content-Type': media.type,
            },
          })
        } catch (error: unknown) {
          toast.warn('Some files failed to sent.')
        }
      }

      return mediaUrls
    },
    [sendAttachments]
  )

  return {
    storeAttachmentsAndGetS3Paths,
  }
}
