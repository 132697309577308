import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface DeleteWebSubscriptionParams {
  subscriptionId: number
}

export const useDeleteWebSubscription = (fetchOnCreate = false) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: DeleteWebSubscriptionParams) => {
      const client = await getClient()

      await client.delete(`/users/subscriptions/${params.subscriptionId}`)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['web-subscriptions'],
      })
    },
  })
}
