import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface CreateWebSubscriptionParams {
  subscription: PushSubscription
}

export const useCreateWebSubscription = (fetchOnCreate = false) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: CreateWebSubscriptionParams) => {
      const client = await getClient()

      await client.post('/users/subscriptions', {
        subscription: JSON.stringify(params.subscription),
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['web-subscriptions'],
      })
    },
  })
}
