import styled from 'styled-components'

import { Body } from 'src/stories/typography'

export const ConversationListItemContainer = styled.div<{
  $active: boolean
  $editMode?: boolean
}>(({ $active, theme, $editMode }) => ({
  display: 'grid',
  gridTemplateColumns: $editMode ? `10% 10% 70% 10%` : `10% 80% 10%`,
  padding: `${theme.space(2.5)} 0`,

  color: theme.colors.base_50,
  cursor: 'pointer',
  backgroundColor: $active ? theme.colors.primary_1_10 : theme.colors.base_0,

  '&:hover': {
    backgroundColor: theme.colors.primary_1_10,
  },
}))

export const ConversationListItemOptionsContainer = styled.div(({ theme }) => ({
  gridRow: 'span 4 / span 4',
  justifySelf: 'center',
  alignContent: 'center',
}))

export const ConversationListItemHeadingContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.space(2),
  '> *': { whiteSpace: 'nowrap' },
}))

export const ConversationListItemHeadingNameContainer = styled(Body)(
  ({ theme }) => ({
    flexGrow: 1,
  })
)

export const ConversationListItemStatusContainer = styled.div<{
  isVisible?: boolean
}>(({ theme, isVisible = true }) => ({
  height: theme.space(4),
  width: theme.space(4),
  visibility: isVisible ? 'visible' : 'hidden',
  gridRow: 'span 4 / span 4',
  paddingLeft: theme.space(4),
  marginTop: theme.space(1),
}))

export const ConversationListItemContactTagContainer = styled.div(
  ({ theme }) => ({
    display: 'flex',
  })
)
