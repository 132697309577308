import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { SortDirection, useGetContacts } from 'src/api'
import { getContactDisplayName } from 'src/client/formatters'
import { useHandleOnSend } from 'src/components/MessagingHub/ConversationsPane/hooks'
import MessageSend from 'src/components/MessagingHub/MessagesPane/MessageSend'
import {
  StyledNewConvoSearchContainer,
  StyledNewConvoSearchItem,
} from 'src/components/MessagingHub/styled'
import { HandleTabConversationChangeFn } from 'src/containers/MessagingHub/types'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { useDebouncer } from 'src/hooks/useDebounce'
import Dropdown, { closeDropdownEvent } from 'src/stories/Dropdown'
import Input from 'src/stories/Input'
import { Row, Col } from 'src/stories/Layout'
import { Body } from 'src/stories/typography'
import {
  formatPhoneNumber,
  isPhoneNumberValid,
  unFormatPhoneNumber,
} from 'src/utils'
import logger from 'src/utils/logger'

const StyledModalContainer = styled(Row)(({ theme }) => ({
  width: '100%',
  maxHeight: '100%',
}))

interface Props {
  locationId: number
  handleTabConversationChange: HandleTabConversationChangeFn
}

const NewConversationModal: React.FC<Props> = ({
  locationId,
  handleTabConversationChange,
}) => {
  const { closeModal } = useModalNotificationsContext()
  const inputRef = useRef<HTMLInputElement>(null)
  const [newConversationInputValue, setNewConversationInputValue] = useState('')
  const {
    data: contactsData,
    refetch,
    isFetching: isSearching,
  } = useGetContacts(
    {
      locationId,
      pagination: { skip: 0, take: 10 },
      search:
        unFormatPhoneNumber(newConversationInputValue) ??
        newConversationInputValue,
      sort: { direction: SortDirection.ASC, field: 'firstName' },
    },
    false
  )

  const [newConversationContact, setNewConversationContact] = useState<number>()
  const [newConversationContactChannelId, setNewConversationContactChannelId] =
    useState<number>()

  const contactsSearch = useMemo(
    () => contactsData?.data.filter((c) => c.phoneNumber) || [],
    [contactsData]
  )

  const isValidPhoneNumber = useMemo(() => {
    const formattedPhoneNumber = formatPhoneNumber(newConversationInputValue)

    return isPhoneNumberValid(formattedPhoneNumber)
  }, [newConversationInputValue])

  const shouldShowDropdown =
    !newConversationContact && newConversationInputValue.length >= 3

  useEffect(() => {
    if (!newConversationContact && isValidPhoneNumber) {
      setNewConversationInputValue(formatPhoneNumber(newConversationInputValue))
    }
  }, [isValidPhoneNumber, newConversationContact, newConversationInputValue])

  const handleOnSend = useHandleOnSend({
    newConversationInputValue,
    newConversationContact,
    newConversationContactChannelId,
    closeModal,
    locationId,
    handleTabConversationChange,
  })

  const handleContactSearch = useDebouncer<(inputValue: string) => void>(
    useCallback(
      async (inputValue) => {
        if (inputValue.length >= 3) {
          await refetch()
        }
      },
      [refetch]
    )
  )

  return (
    <StyledModalContainer direction="column">
      <Row>
        <Col>
          <Input
            ref={inputRef}
            data-cy="search-input"
            name="search-input"
            label="Enter a name or phone number:"
            value={newConversationInputValue}
            onChange={(e) => {
              setNewConversationContact(undefined)
              setNewConversationInputValue(e.target.value)
              handleContactSearch(e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <MessageSend
            onAttach={() => logger.debug('Attach')}
            onSend={handleOnSend}
            height={50}
            errorMessage=""
            forceDisable={!newConversationContact && !isValidPhoneNumber}
            withMargins={false}
            dayaCyUniquePrefix="modal"
            isInModal
          />
        </Col>
      </Row>

      <Dropdown
        anchor={inputRef}
        hideArrow
        alignment="left"
        shouldShowDropdown={shouldShowDropdown}
      >
        <StyledNewConvoSearchContainer
          data-cy={`mh-new-conversation-modal-search-items`}
        >
          {isSearching && (
            <StyledNewConvoSearchItem>
              <Body as="p" fontWeight="bold" size="small" color="dark">
                Loading...
              </Body>
            </StyledNewConvoSearchItem>
          )}
          {!isSearching && contactsSearch.length === 0 && (
            <StyledNewConvoSearchItem
              data-cy={`mh-new-conversation-modal-no-search-item`}
            >
              <Body as="p" fontWeight="bold" size="small" color="dark">
                No contacts found
              </Body>
            </StyledNewConvoSearchItem>
          )}
          {!isSearching &&
            contactsSearch.map((cs, idx) => (
              <StyledNewConvoSearchItem
                data-cy={`mh-new-conversation-modal-search-item-${idx}`}
                key={idx}
                onClick={() => {
                  setNewConversationInputValue(
                    getContactDisplayName(
                      cs.firstName,
                      cs.lastName,
                      cs.phoneNumber
                    )
                  )
                  setNewConversationContact(cs.id)
                  setNewConversationContactChannelId(
                    cs.channels.find((ch) => ch.phoneNumber === cs.phoneNumber)
                      ?.id
                  )
                  inputRef.current?.dispatchEvent(closeDropdownEvent)
                }}
              >
                {
                  <Body
                    as="p"
                    fontWeight="bolder"
                    size="small"
                    color="darker"
                    data-cy={`mh-new-conversation-modal-search-item-${idx}-name`}
                  >
                    {cs.firstName || cs.lastName
                      ? getContactDisplayName(
                          cs.firstName,
                          cs.lastName,
                          cs.phoneNumber
                        )
                      : ''}
                  </Body>
                }
                {cs.phoneNumber && (
                  <Body
                    as="p"
                    fontWeight="bolder"
                    color="darker"
                    size="small"
                    data-cy={`mh-new-conversation-modal-search-item-${idx}-number`}
                  >{`${formatPhoneNumber(cs.phoneNumber)}`}</Body>
                )}
              </StyledNewConvoSearchItem>
            ))}
        </StyledNewConvoSearchContainer>
      </Dropdown>
    </StyledModalContainer>
  )
}

export default NewConversationModal
